import { FilterDateDashboard } from "../../../../components/Dashboard/ViewDatas/FilterDate";
import { ReactComponent as BagFood } from "../../../../assets/svg/icons/bagFood.svg";
import { DateModal } from "../../../../components/Modal/DateFilter";
import { useFilter } from "../../../../hooks/useFilter";
import Constants from "../../../../constants/Index";
import axios from "../../../../services/axios"
import { useEffect, useState } from "react";
import { IOptionProps } from "../../../../@types/reactSelect/IOption";
import { Dropdown } from "../../../../components/EditOnboarding/components/Select";
import { useForm } from "react-hook-form";

export default function DashboardSupplementationProductConsumption() {
  const {
    setHideMicroAreaFilter,
    setHideBatchFilter,
    setDashboard,
    setSideBarOptionSelected,
  } = useFilter();

  const {
    handleSubmit,
    control,
    watch,
    setValue
  } = useForm();

  const [optionsClassification, setOptionsClassification] = useState<IOptionProps[]>([]);
  const [optionsProduct, setOptionsProduct] = useState<IOptionProps[]>([]);
  const selectedClassification = watch("classification")


  useEffect(() => {
    setHideMicroAreaFilter(true)
    setHideBatchFilter(true)
    setSideBarOptionSelected("DASHBOARD")
    setDashboard("SUPPLEMENTATION")
    document.title = Constants.TITLE_DASHBOARD;
  }, []);

  useEffect(() => {
    axios.get("/products/supplement/classification")
      .then(({ data }) => {
        setOptionsClassification(
          data.map((el: any) => (
            {
              label: el.name,
              value: el.id,
            }
          ))
        )
      })

    axios.get("/products/all")
      .then(({ data }) => {
        setOptionsProduct(
          data.map((el: any) => (
            {
              label: el.trade_name,
              value: el.id,
              info: el.classification
            }
          ))
        )
      })
  }, []);

  const IconMarq = (value: number, min: number, max: number) => {
    let hex = "FFFFFF";

    if (value <= min) {
      hex = "FF0000";
    } else if (value >= min && value <= max) {
      hex = "FFFF00";
    } else {
      hex = "00FF00";
    }

    return (
      <div style={{
        width: 20,
        height: 20,
        background: `#${hex}`,
        borderRadius: 50,
        border: "1px solid #FFF"
      }} />
    )
  }

  const LegendaTable = (title: string, red: number, green: number) => {

    return (
      <div className="tableDashboard" id={"estoque_suple"}>
        <table>
          <thead style={{
            color: "#fff"
          }}>
            <th scope="col" rowSpan={2} style={{
              background: "#324440",
              width: "180px",
              textAlign: "left"
            }}>
              {title}
            </th>

            {[{
              legenda: `>${red}%`
            },
            {
              legenda: `>=${green} <=${red}%`
            },
            {
              legenda: `<=${green}%`
            }].map((item, index) => (
              <th scope="col" rowSpan={2} style={{
                background: "#324440",
                width: "150px",
                textAlign: "left"
              }}>
                {item.legenda}
              </th>
            ))}
          </thead>

          <tbody>
            <tr className="bg_white">
              <th scope="row"></th>
              <td style={{
                textAlign: "left"
              }}>{IconMarq(1, 30, 50)}</td>
              <td style={{
                textAlign: "left"
              }}>{IconMarq(35, 30, 50)}</td>
              <td style={{
                textAlign: "left"
              }}>{IconMarq(55, 30, 50)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <div className="cattleMap_background" style={{ overflowY: "auto" }}>
      <form onSubmit={() => handleSubmit(() => { })}>

        <DateModal />

        <div className="cattleMap_page">
          <div className="cattleMap_header">
            <BagFood height={72} />
            <div style={{
              display: "flex",
              flexDirection: "column",
            }}>
              <h2 className="cattleMap_title">
                Suplementação
              </h2>
              <h3 className="cattleMap_subtitle">
                Consumo por mês
              </h3>
            </div>
          </div>

          <div>
            <FilterDateDashboard />

            <div style={{ width: 400 }}>
              <Dropdown
                control={control}
                label={"CLASSIFICAÇÂO"}
                name={"classification"}
                closeMenuOnSelect={true}
                placeholderText={"Selecione..."}
                options={optionsClassification.filter((classification) => optionsProduct.map(el => el.info).includes(classification.label))}
                getSelectedData={() => {
                  setValue("products", null)
                }}
              />


              <Dropdown
                control={control}
                label={"PRODUTO"}
                name={"products"}
                closeMenuOnSelect={true}
                placeholderText={"Selecione..."}
                noOptionsMessage={() => "Nenhum produto disponível"}
                options={selectedClassification ? optionsProduct.filter(product => product.info == selectedClassification.label) : []}
              />
            </div>
          </div>
        </div>

        <br />

        {
          <div className="tableDashboard" id={"analise_geral"}>
            <h2>Consumo por mês</h2>
            <table>
              <thead style={{
                color: "#fff"
              }}>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "280px",
                  textAlign: "left"
                }}>
                  Retiro
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "150px"
                }}>
                  Jan-22
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "150px"
                }} >
                  Fev-22
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "150px"

                }}>
                  Mar-22
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "150px"
                }}>
                  Abr-22
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "150px"

                }}>
                  May-22
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "150px"
                }} >
                  Jun-22
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "150px"
                }}>
                  Jul-22
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "150px"
                }}>
                  Aug-22
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "150px"

                }}>
                  Set-22
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "150px"
                }} >
                  Out-22
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "150px"
                }}>
                  Nov-22
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "150px"
                }}>
                  Dec-22
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "150px"
                }}>
                  Média Anual
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "210px"
                }}>
                  Frequência de Reposição
                  <br />
                  (Média de dias)
                </th>
              </thead>

              <tbody>
                {
                  [{
                    retiro: "Retiro 1",
                    jan: 0,
                    fev: 0,
                    mar: 0,
                    abr: 0,
                    may: 0,
                    jun: 0,
                    jul: 0,
                    aug: 0,
                    set: 0,
                    out: 0,
                    nov: 0,
                    dec: 0,
                    media: 0,
                    freq: 0
                  },
                  {
                    retiro: "Retiro 2",
                    jan: 0,
                    fev: 0,
                    mar: 0,
                    abr: 0,
                    may: 0,
                    jun: 0,
                    jul: 0,
                    aug: 0,
                    set: 0,
                    out: 0,
                    nov: 0,
                    dec: 0,
                    media: 0,
                    freq: 0
                  }, {
                    retiro: "Retiro 3",
                    jan: 0,
                    fev: 0,
                    mar: 0,
                    abr: 0,
                    may: 0,
                    jun: 0,
                    jul: 0,
                    aug: 0,
                    set: 0,
                    out: 0,
                    nov: 0,
                    dec: 0,
                    media: 0,
                    freq: 0
                  }, {
                    retiro: "Retiro 4",
                    jan: 0,
                    fev: 0,
                    mar: 0,
                    abr: 0,
                    may: 0,
                    jun: 0,
                    jul: 0,
                    aug: 0,
                    set: 0,
                    out: 0,
                    nov: 0,
                    dec: 0,
                    media: 0,
                    freq: 0
                  }, {
                    retiro: "Retiro 5",
                    jan: 0,
                    fev: 0,
                    mar: 0,
                    abr: 0,
                    may: 0,
                    jun: 0,
                    jul: 0,
                    aug: 0,
                    set: 0,
                    out: 0,
                    nov: 0,
                    dec: 0,
                    media: 0,
                    freq: 0
                  }, {
                    retiro: "Retiro 6",
                    jan: 0,
                    fev: 0,
                    mar: 0,
                    abr: 0,
                    may: 0,
                    jun: 0,
                    jul: 0,
                    aug: 0,
                    set: 0,
                    out: 0,
                    nov: 0,
                    dec: 0,
                    media: 0,
                    freq: 0
                  }, {
                    retiro: "Retiro 7",
                    jan: 0,
                    fev: 0,
                    mar: 0,
                    abr: 0,
                    may: 0,
                    jun: 0,
                    jul: 0,
                    aug: 0,
                    set: 0,
                    out: 0,
                    nov: 0,
                    dec: 0,
                    media: 0,
                    freq: 0
                  }, {
                    retiro: "Retiro 8",
                    jan: 0,
                    fev: 0,
                    mar: 0,
                    abr: 0,
                    may: 0,
                    jun: 0,
                    jul: 0,
                    aug: 0,
                    set: 0,
                    out: 0,
                    nov: 0,
                    dec: 0,
                    media: 0,
                    freq: 0
                  }, {
                    retiro: "Retiro 9",
                    jan: 0,
                    fev: 0,
                    mar: 0,
                    abr: 0,
                    may: 0,
                    jun: 0,
                    jul: 0,
                    aug: 0,
                    set: 0,
                    out: 0,
                    nov: 0,
                    dec: 0,
                    media: 0,
                    freq: 0
                  }, {
                    retiro: "Retiro 10",
                    jan: 0,
                    fev: 0,
                    mar: 0,
                    abr: 0,
                    may: 0,
                    jun: 0,
                    jul: 0,
                    aug: 0,
                    set: 0,
                    out: 0,
                    nov: 0,
                    dec: 0,
                    media: 0,
                    freq: 0
                  }].map((item, index) => (
                    <tr className={index % 2 === 0 ? " bg_white" : "bg_gray"}>
                      <th scope="row">{`${item.retiro}`}</th>
                      <td>{item.jan}</td>
                      <td>{item.fev}</td>
                      <td>{item.mar}</td>
                      <td>{item.abr}</td>
                      <td>{item.may}</td>
                      <td>{item.jun}</td>
                      <td>{item.jul}</td>
                      <td>{item.aug}</td>
                      <td>{item.set}</td>
                      <td>{item.out}</td>
                      <td>{item.nov}</td>
                      <td>{item.dec}</td>
                      <td>{item.media}</td>
                      <td>{item.freq}</td>
                    </tr>
                  ))
                }
              </tbody>
              <tfoot
                style={{
                  background: "#324440",
                  color: "#fff"
                }}
              >
                <th style={{
                  textAlign: "left"
                }}>Somatória da Fazenda</th>
                <td>{0}</td>
                <td>{0}</td>
                <td>{0}</td>
                <td>{0}</td>
                <td>{0}</td>
                <td>{0}</td>
                <td>{0}</td>
                <td>{0}</td>
                <td>{0}</td>
                <td>{0}</td>
                <td>{0}</td>
                <td>{0}</td>
                <td>{0}</td>
                <td>{0}</td>
              </tfoot>
            </table>
          </div>
        }

      </form >
    </div >
  );
}
