import { ReactNode, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { ReactComponent as ChevronRighticon } from "../../../../assets/svg/icons/chevronRight.svg";
import { ReactComponent as Circleicon } from "../../../../assets/svg/icons/circle.svg";

type MenuOptionProps = {
    title?: string;
    to?: string;
    basePath: string;
    onClicked?: () => void;
    children: ReactNode;
};

export function SubMenuOption({ title, to, basePath, children, onClicked }: MenuOptionProps) {
    const navigate = useNavigate();
    const [selected, setSelected] = useState<boolean>(false);

    function isParentSelected(parentOption: string): boolean {
        return parentOption ? window.location.pathname.includes(parentOption) : false;
    }

    useEffect(() => {
        isParentSelected(basePath) ? setSelected(true) : setSelected(false)
    }, [window.location.pathname]);

    return (
        <>
            <button
                className={`sidebar_menuoption--button sidebar_menuoption${selected && "--selected"}`}
                onClick={() => {
                    onClicked ? onClicked() : () => { }
                    setSelected(!selected)
                    if (to) {
                        navigate(to);
                    }
                }}
            >
                <div
                    className={`sidebar_subMenuoptionIcon${selected ? "--selected" : ""}`}
                >
                    {selected ? <ChevronRighticon /> : <Circleicon />}
                </div>
                <span id="sidebar_menuoption--text">{title}</span>
            </button>

            <div
                className={`sidebar_subMenuoption--collapsible${selected ? "--selected" : ""}`}
            >
                {children}
            </div>
        </>
    );
}
