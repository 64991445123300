import { useFilter } from '../../hooks/useFilter';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import './styles.css'

type DaySelectProps = {
    selectOnlyMonth: boolean;
}

export default function DaySelect({ selectOnlyMonth = false }: DaySelectProps) {
    const {
        focusLastDay,
        setFocusLastDay,
        viewRangeDays,
        rangeDays,
        setRangeDays,
        setViewRangeDays,
        onBoardingDate
    } = useFilter();

    function handleDayClick(day: Date) {
        const now = day;
        const firstDayOfCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1);

        const from = selectOnlyMonth
            ? (focusLastDay ? rangeDays?.from : firstDayOfCurrentMonth)
            : (focusLastDay ? rangeDays?.from : day);

        const to = focusLastDay ? day : rangeDays?.to;

        setRangeDays({ from, to });
        setFocusLastDay(!focusLastDay)
    }

    function handleDayMouseEnter(day: Date) {
        if (day >= viewRangeDays?.to!) {
            setViewRangeDays({
                from: focusLastDay ? rangeDays?.from : day,
                to: focusLastDay ? day : rangeDays?.to,
            })
        } else {
            setViewRangeDays({
                from: focusLastDay ? rangeDays?.from : day,
                to: focusLastDay ? day : rangeDays?.to,
            })
        }
    }

    const markDays = [
        rangeDays?.from ?? undefined,
        rangeDays?.to ?? undefined,
    ];

    return (
        <div onMouseLeave={() => {
            setViewRangeDays(rangeDays)
        }}>
            <DayPicker
                mode="range"
                selected={viewRangeDays}
                fromDate={focusLastDay ? rangeDays?.from : onBoardingDate}
                toDate={focusLastDay ? new Date() : rangeDays?.to}
                onDayMouseEnter={handleDayMouseEnter}
                onDayClick={handleDayClick}
                showOutsideDays
                fixedWeeks
                modifiers={{
                    selectedRange: rangeDays ? markDays.filter((day): day is Date => day !== undefined) : []
                }}
                modifiersClassNames={{
                    selectedRange: "rpd-days-range_selected"
                }}
            />
        </div>
    );
}