
import * as d3 from "d3-scale";
import { interpolateRgb } from "d3-interpolate";

export function getColorFromValue(value: number, percentBreak: number[], colorArray: string[]): string {
    const scale = d3
        .scaleLinear<string>()
        .domain(percentBreak)
        .range(colorArray)
        .interpolate(interpolateRgb);

    return scale(value);
}
