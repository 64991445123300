import { useEffect, useRef, useState } from "react";
import { Polygon, Tooltip } from "react-leaflet";
import "./styles.css";

import { useFilter } from "../../../../hooks/useFilter";

import {
  FlyToBounds,
  FlyToBoundsRefProps,
  IAnimationState,
} from "../../../../components/Leaflet/FlyToBounds/Index";

import { BatchesSpecsDetails } from "../../../../components/PaddocksMap/BatchesSpecsDetails";
import { SpecsMouseOver } from "../../../../components/PaddocksMap/SpecsMouseOver/Index";
import ContainerZoom1X from "../../../../components/Leaflet/ContainerZoom1X/Index";
import ContainerZoom2X from "../../../../components/Leaflet/ContainerZoom2X/Index";
import ContainerZoom3X from "../../../../components/Leaflet/ContainerZoom3X/Index";
import ContainerZoom4X from "../../../../components/Leaflet/ContainerZoom4X/Index";
import { PolylineFarm } from "../../../../components/PaddocksMap/PolylineFarm/Index";
import { BatchesIcons } from "../../../../components/PaddocksMap/BatchesIcons/Index";
import { LabelFarm1X } from "../../../../components/PaddocksMap/LabelFarm1X/Index";
import { AnimatedZoom } from "../../../../components/Leaflet/AnimatedZoom/Index";

import { ICoord, ICoords } from "../../../../@types/GoogleMaps/ICoord";
import { IMicroAreas } from "../../../../@types/API/IMicroArea";
import { IFarm, IFarms } from "../../../../@types/API/IFarm";
import { IBatch } from "../../../../@types/API/IBatches";
import { IArea } from "../../../../@types/API/IArea";

import { getCenterCoords } from "../../../../utils/leaflet/getCenterCoords";
import Constants from "../../../../constants/Index";
import axios from "../../../../services/axios";
import { CustomControls } from "../../../../components/Leaflet/CustomControls/Index";

import { normalizeToScale } from "../../../../utils/numbers/normalizeToPercent";
import { getColorFromValue } from "../../../../utils/colors/colorFromAScale";

type ICustomFarm = {
  id: string;
  initials: string;
  coords: ICoords;
  name: string;
  bgColor: string;
};

type ICustomArea = {
  id: string;
  initials: string;
  coords: ICoords;
  name: string;
  farmId?: string;
};

type ICustomBatch = {
  initials: string;
  coords: ICoord;
  id: string;
  farmId: string;
  areaId: string;
  visible: boolean;
  capacityRate: number;
};

type ICustomMicroArea = {
  batch_initials: string;
  id: string;
  initials: string;
  coords: ICoords;
  capacityRate: number;
  areaId: string;
  farmId: string;
  areaInitials: string;
  amountAnimals: number;
  currentMicroArea: boolean;
  acreage: number;
  bgColor: string;
  area: any;
  farm: any;
};

interface ICustomFarms extends Array<ICustomFarm> { }
interface ICustomAreas extends Array<ICustomArea> { }
interface ICustomBatches extends Array<ICustomBatch> { }
interface ICustomMicroAreas extends Array<ICustomMicroArea> { }

type CapacityViewProps = {
  view: "immediate" | "avarege"
}

export function PastureCapacity({ view }: CapacityViewProps) {
  const flytoBoundsRef = useRef<FlyToBoundsRefProps>();

  const { farmFilter, setFarmFilter, areaFilter, defaultBGFarm, setHideMicroAreaFilter, setHideBatchFilter } =
    useFilter();

  const [currentFarm, setCurrentFarm] = useState<IFarm | undefined>(undefined);
  const [areas, setAreas] = useState<IArea | undefined>(undefined);

  const [currentArea, setCurrentArea] = useState<ICustomArea | undefined>(undefined);

  const [areasPolygons, setAreasPolygons] = useState<ICustomAreas>([]);

  const [farmsPolygons, setFarmsPolygons] = useState<ICustomFarms>([]);

  const [microAreasPolygons, setMicroAreasPolygons] = useState<ICustomMicroAreas>([]);

  const [batchesPolygons, setBatchesPolygons] = useState<ICustomBatches>([]);

  const [stateOfAnimation, setStateOfAnimation] = useState<IAnimationState>("end");

  function setColor(capacity_rate: number, farmId: string, areaId: string) {
    if (areaFilter && farmFilter.id === farmId && areaFilter.id !== areaId)
      return Constants.CAPACITYROUTE_COLORS.WHITE;

    const currentValueColor = normalizeToScale(0, 7, capacity_rate)
    return getColorFromValue(currentValueColor, [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100], ["#FFFFFF", "#FFEAD8", "#FFD5B1", "#FFC08A", "#FFAB63", "#FF9501", "#FF7727", "#FF593E", "#FF3C58", "#FF1E79", "#FF0000"])
  }

  async function getBatchesAvailables(area: ICustomArea, farmId: string) {
    const { data, status } = await axios.get("/micro-areas/available", { params: { area_id: area.id } });
    if (data && data.length > 0) {
      return data.map(({ acreage, id, initials, map_coords: coords }: any) => ({
        acreage,
        areaId: area.id,
        id,
        initials,
        coords,
        capacityRate: -1,
        farmId,
        areaInitials: area.initials,
      }));
    } else return [];
  }

  async function handleBatchesData(farms: IFarms) {
    const batchesParsed = await Promise.all(
      farms.map(async (farm) => {
        const { data, status } = await axios.get("/batches", { params: { farm_id: farm.id } });
        return data.map(
          ({
            id,
            area,
            current_micro_area,
            micro_area_route,
            batch_capacity_rate,
            animals_quantity: amountAnimals,
            initials: batch_initials
          }: IBatch) => {
            const responseMicro = micro_area_route
              .map(
                ({ id, initials, map_coords: coords }) =>
                ({
                  capacityRate: batch_capacity_rate,
                  coords,
                  initials,
                  id,
                  batch_initials,
                  farmId: farm.id,
                  areaId: area.id,
                  areaInitials: area.initials,
                  amountAnimals,
                  bgColor: view == "immediate" ?
                    setColor(current_micro_area.acreage / somaUA(area), farm.id as string, area.id) :
                    setColor(batch_capacity_rate, farm.id as string, area.id),
                  currentMicroArea: id == current_micro_area.id,
                  acreage: current_micro_area.acreage,
                  area
                } as ICustomMicroArea)
              );

            setMicroAreasPolygons((microAreas) => [...microAreas, ...responseMicro]);
            return {
              id,
              coords: getCenterCoords(current_micro_area.map_coords),
              initials: current_micro_area.initials,
              farmId: farm.id,
              areaId: area.id,
              visible: areaFilter && farm.id === farmFilter.id ? area.id === areaFilter.id : true,
              capacityRate: batch_capacity_rate
            } as ICustomBatch;
          }
        );
      })
    );
    const batchesFiltereds = batchesParsed.flat();
    setBatchesPolygons(() => batchesFiltereds);
  }

  useEffect(() => {
    setMicroAreasPolygons((microAreas) => microAreas.map(ma => {

      return ({
        ...ma,
        bgColor: view == "immediate" ?
          setColor(ma.acreage / somaUA(areasPolygons.filter((a) => a.id === ma.areaId)[0]), ma.farmId as string, ma.areaId) :
          setColor(ma.capacityRate, ma.farmId as string, ma.areaId),
      })
    }));
  }, [batchesPolygons, areasPolygons]);

  async function handleAreasData(farms: IFarms) {
    const areasData = await Promise.all(
      farms.map(async (farm) => {
        const { data, status } = await axios.get("/areas", { params: { farm_id: farm.id } });
        setAreas(data)
        const response = data.map((area: ICustomArea) => ({ ...area, farmId: farm.id }));
        return status === Constants.STATUS_CODE_OK ? response : [];
      })
    );
    setAreasPolygons(() =>
      areasData.flat().map((micro) => ({
        id: micro.id,
        initials: micro.initials,
        coords: micro.map_coords,
        name: micro.name,
        farmId: micro.farmId,
      }))
    );
  }

  function somaUA(areas: IArea) {
    if (!areas) return 0;

    let value = batchesPolygons
      .filter((batch) => batch.areaId == areas?.id)
      .map((values) => values.capacityRate);

    let media = 0;
    if (value.length > 0) {
      media = value.reduce((soma, i) => soma + i);
    }

    return media;
  }

  function qtdBatch(areas: IArea) {
    if (batchesPolygons.filter((batch) => batch.areaId == areas.id).length == 0) return 1;
    else return batchesPolygons.filter((batch) => batch.areaId == areas.id).length;
  }

  function overrideCentermap() {
    setStateOfAnimation("start");
    if (flytoBoundsRef && flytoBoundsRef.current && farmFilter)
      flytoBoundsRef.current?.flyToBounds(farmFilter?.coords);
  }

  function handleFocusCenter() {
    const coords: ICoords = areaFilter ? areaFilter.coords : farmFilter.coords;
    if (coords && flytoBoundsRef.current?.canZoomAnimate(coords, 50)) {
      flytoBoundsRef.current.flyToBounds(coords);
    } else setStateOfAnimation("end");
  }

  useEffect(() => {
    if (farmFilter && flytoBoundsRef) handleFocusCenter();
  }, [farmFilter, flytoBoundsRef]);

  useEffect(() => {
    if (farmFilter) {
      let current: ICustomArea | undefined = areaFilter;
      setCurrentArea(current);
      setMicroAreasPolygons((microAreas) =>
        microAreas.map((micro) => ({
          ...micro,
          bgColor: setColor(micro.capacityRate, micro.farmId, micro.areaId),
        }))
      );
      setBatchesPolygons((batches) =>
        batches.map((batch) => ({
          ...batch,
          visible: current && current?.farmId === batch.farmId ? current.id === batch.areaId : true,
        }))
      );
      handleFocusCenter();
    }
  }, [areaFilter, farmFilter]);

  useEffect(() => {
    if (farmFilter && flytoBoundsRef) {
      let responseCoords = farmFilter.coords;
      if (areaFilter) {
        const { coords } = areaFilter;
        responseCoords = coords;
      }
      if (flytoBoundsRef.current?.canZoomAnimate(responseCoords)) {
        flytoBoundsRef.current.flyToBounds(responseCoords);
      } else setStateOfAnimation("end");
    }
  }, [farmFilter, flytoBoundsRef]);

  useEffect(() => {
    if (currentFarm) {
      axios
        .get("/batches", { params: { farm_id: currentFarm.id } })
        .then(({ data }) => {
          data.map((batch: IBatch) => {
            const bgColor = setColor(batch.batch_capacity_rate, currentFarm.id as string, batch.area.id);
            const routes = batch.micro_area_route.map(({ id, initials, map_coords }) => ({
              id,
              initials,
              map_coords,
              bgColor,
              areaId: batch.area.id,
            })) as IMicroAreas;

            return {
              areaProps: batch.area,
              microAreas: routes,
            };
          });
        })
        .catch((error) => console.log(error));
    }
  }, [currentFarm]);

  useEffect(() => {
    areasPolygons.forEach(async (area) => {
      const availables = await getBatchesAvailables(area, area.farmId as string);
      setMicroAreasPolygons((microAreas) => [...microAreas, ...availables]);
    });
  }, [areasPolygons]);

  useEffect(() => {
    axios
      .get("/farms/user")
      .then(({ data }) => {
        const [currentFarm] = data;
        if (currentFarm) {
          const { id, initials, name, map_coords: coords } = currentFarm;
          if (!farmFilter)
            setFarmFilter({
              id,
              initials,
              name,
              coords,
            } as ICustomFarm);
        }
        setFarmsPolygons(() =>
          data.map(
            ({ id, initials, name, map_coords: coords }: IFarm) =>
              ({ id, initials, name, coords } as ICustomFarm)
          )
        );
        setAreasPolygons(() => []);
        setMicroAreasPolygons(() => []);
        handleAreasData(data);
        handleBatchesData(data);
        setCurrentFarm(currentFarm);
      })
      .catch((error) => console.log(error));
    if (view == "avarege") {
      document.title = Constants.TITLE_PADDOCKS_MAP_CAPACITY_AVAREGE;
    } else {
      document.title = Constants.TITLE_PADDOCKS_MAP_CAPACITY_IMMEDIATE;
    }
    setHideMicroAreaFilter(true);
    setHideBatchFilter(true);
  }, [, view]);

  function handleBGOppacity(farmId: string, areaId: string) {
    if (farmId !== farmFilter.id) return 0.55;
    if (areaFilter && areaId !== areaFilter.id) return 0.1;
    return 0.55;
  }

  function handleBorderColor(farmId: string, areaId: string) {
    if (farmId !== farmFilter.id) return Constants.PADDOCKTIME_COLORS.WHITE;
    if (areaFilter && areaId !== areaFilter.id) return Constants.LEAFLET_UNFOCUS.color;
    return Constants.PADDOCKTIME_COLORS.WHITE;
  }

  const getBgColor = (view: "immediate" | "avarege", micro: ICustomMicroArea) => {
    if ((view === "immediate" && micro.currentMicroArea) || view === "avarege") {
      return micro.bgColor;
    }
    return "#FFF";
  };

  return (
    <div className="defaultmap__container">
      <FlyToBounds ref={flytoBoundsRef} onStateAnimation={(state) => setStateOfAnimation(state)}>
        {farmsPolygons.map((farm, key) => (
          <AnimatedZoom key={key} coordsToBoundZoom={farm.coords} updateZoomVisualization={stateOfAnimation}>
            <ContainerZoom1X>
              <LabelFarm1X
                key={farm.initials}
                position={getCenterCoords(farm.coords)}
                initials={`${farm.initials}${currentArea && currentArea.farmId === farm.id ? `-${currentArea.initials}` : ""}`}
              />
            </ContainerZoom1X>

            <ContainerZoom2X>
              <Polygon
                fillColor={defaultBGFarm}
                positions={farm.coords}
                fillOpacity={0.55}
                color={"#FFF"}
                fill={true}
                weight={2}
              >
                <Tooltip className="customToolTip__container" sticky>
                  <SpecsMouseOver microAreaLabel={`${farm.initials}`} type={"farm"} />
                </Tooltip>
              </Polygon>
            </ContainerZoom2X>

            <ContainerZoom3X>
              <PolylineFarm path={farm.coords} />
              <BatchesIcons batches={batchesPolygons.filter((f) => f.farmId === farm.id)} />
              {areasPolygons
                .filter((f) => f.farmId === farm.id)
                .map((area, key) => (
                  <Polygon
                    key={key}
                    pathOptions={{
                      fillColor: setColor(somaUA(area) / qtdBatch(area), area.farmId as string, area.id),
                      fillOpacity: handleBGOppacity(area.farmId as string, area.id),
                      color: handleBorderColor(area.farmId as string, area.id),
                    }}
                    weight={2}
                    fill={true}
                    positions={area.coords}
                  >
                    <Tooltip className="customToolTip__container" sticky>
                      <SpecsMouseOver
                        microAreaLabel={`${currentFarm?.initials}-${area.initials}`}
                        type={"area"}
                      />
                    </Tooltip>
                  </Polygon>
                ))}
            </ContainerZoom3X>

            <ContainerZoom4X>
              <PolylineFarm path={farm.coords} />
              <BatchesIcons batches={batchesPolygons.filter((f) => f.farmId === farm.id)} />
              {microAreasPolygons
                .filter((f) => f.farmId === farm.id)
                .map((micro, key) => (
                  <Polygon
                    key={key}
                    pathOptions={{
                      fillColor: getBgColor(view, micro),
                      fillOpacity: handleBGOppacity(micro.farmId, micro.areaId),
                      color: handleBorderColor(micro.farmId, micro.areaId),
                    }}
                    positions={micro.coords}
                    fill={true}
                    weight={2}
                  >
                    <Tooltip className="customToolTip__container" sticky>
                      <SpecsMouseOver
                        className={micro.amountAnimals ? "havebatch" : ""}
                        microAreaLabel={`${currentFarm?.initials}-${micro.areaInitials}-${micro.initials}`}
                        type={"batch"}
                      />
                      {micro.amountAnimals && (
                        <BatchesSpecsDetails
                          batchLabel={`${currentFarm?.initials}-${micro.areaInitials}-${micro.batch_initials}`}
                          qtdLabel={`${micro.amountAnimals} animais`}
                          capacity={view == "immediate" && areasPolygons && areasPolygons.length > 0 ?
                            `${(micro.acreage / somaUA(areasPolygons!.filter((a) => a.id == micro.areaId)[0])).toFixed(2)}` :
                            `${micro.capacityRate}`}
                        />
                      )}
                    </Tooltip>
                  </Polygon>
                ))}
            </ContainerZoom4X>
          </AnimatedZoom>
        ))}
      </FlyToBounds>
      <CustomControls overrideCentermap={overrideCentermap} />
      <section className="capacitybvroute__barnumber--container">
        <strong className="capacitybvroute__barnumber--title">{view == "avarege" ? 'LOTAÇÃO MÉDIA (UA/HA)' : 'LOTAÇÃO INSTANTÂNEA (UA/HA)'}</strong>
        <div className="capacitybvroute__barnumber--bar" />
        <div className="capacitybvroute__barnumber--containerlegend">
          <span>0,00</span>
          <span>1,00</span>
          <span>2,00</span>
          <span>3,00</span>
          <span>4,00</span>
          <span>5,00</span>
          <span>6,00</span>
          <span>7,00</span>
          <span>8,00</span>
        </div>
      </section>
    </div>
  );
}
