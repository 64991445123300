import "leaflet/dist/leaflet.css";
import { ReactNode, useEffect, useState } from "react";
import { MapContainer, MapContainerProps, TileLayer } from "react-leaflet";
import Constants from "../../../constants/Index";

type LeafletMapProps = {
  children?: ReactNode;
  interactionOptions?: MapContainerProps;
};

export function LeafletMap({ children, interactionOptions = {} }: LeafletMapProps) {

  return (
    <MapContainer
      style={{ width: "100%", height: "100%", background: "transparent" }}
      center={Constants.DEFAULT_COORDS}
      zoom={9}
      minZoom={Constants.LEAFLET_ZOOM_MIN}
      maxZoom={Constants.LEAFLET_ZOOM_MAX}
      {...interactionOptions}
    >
      <TileLayer
        url="https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}&s=Ga"
      />
      {children}
    </MapContainer>
  );
}
