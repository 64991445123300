import "./styles.css";
import { ReactComponent as FarmIcon } from "../../assets/svg/icons/farmIcon.svg";
import { ReactComponent as AreaIcon } from "../../assets/svg/icons/areaIcon.svg";
import { ReactComponent as MicroAreaIcon } from "../../assets/svg/icons/grass.svg";
import { ReactComponent as PenIcon } from "../../assets/svg/icons/penEdit.svg";
import { ReactComponent as SearchIcon } from "../../assets/svg/icons/search.svg";
import { ReactComponent as AnimalsIcon } from "../../assets/svg/icons/animais.svg";
import { IOptionsEditOnboarding } from "../../contexts/EditOnboardingContext";
import ComponentChipOnlyView from "../Chips/ComponentChipLabel/Index";
import { useEffect, useRef, useState } from "react";

type CardProps = {
  type: IOptionsEditOnboarding;
  initials: string;
  initialBatch?: string;
  name?: string;
  profile?: string;
  qtdArea?: number;
  qtdMicroArea?: number;
  qtdAnimals?: number;
  managementType?: any;
  onClick?: () => void;
  showFarm?: boolean;
  nameFarm?: string;
  showArea?: boolean;
  nameArea?: string;
  listInitialsMicroAreas?: string[];
};

export function Card({
  type,
  initials,
  initialBatch,
  name,
  profile,
  qtdArea,
  qtdMicroArea,
  qtdAnimals,
  onClick,
  managementType,
  showFarm,
  nameFarm,
  showArea,
  nameArea,
  listInitialsMicroAreas
}: CardProps) {
  const colorsMap = new Map()
  colorsMap.set("Contínuo", "#ff9501af")
  colorsMap.set("Rotacionado", "#ff6161af")
  colorsMap.set("Em faixa", "#0157ffaf")

  const cardContainerRef = useRef<HTMLDivElement>(null);
  const overflowContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const overflowingChildren: HTMLElement[] = [];

    const containerOverflow = overflowContainerRef.current;
    if (!containerOverflow) return;

    const checkOverflowForChildren = () => {
      Array.from(containerOverflow.children).forEach((child) => {
        const element = child as HTMLElement;

        if (!element.hasAttribute("hidden") && Array.from(containerOverflow.children).filter(el => !(el as HTMLElement).hidden).length > 1) {
          if (element.offsetTop + 50 > containerOverflow.clientHeight) {

            overflowingChildren.push(element);
            element.hidden = true
          }
        }
      });
    }

    checkOverflowForChildren();

    const resizeObserver = new ResizeObserver(() => {
      checkOverflowForChildren();
    });

    resizeObserver.observe(containerOverflow);
    return () => {
      resizeObserver.disconnect();
    };
  }, [listInitialsMicroAreas]);

  useEffect(() => {
    const cardContainer = cardContainerRef.current;
    const containerOverflow = overflowContainerRef.current;
    if (!cardContainer) return;

    const cardResize = () => {
      if (containerOverflow) {
        Array.from(containerOverflow.children).forEach((child) => {
          (child as HTMLElement).hidden = false;
        })
      }
    }

    cardResize();

    const resizeObserver = new ResizeObserver(() => {
      cardResize();
    });

    resizeObserver.observe(cardContainer);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div
      className="bodyCard"
      onClick={onClick}
      style={managementType ? {
        backgroundColor: colorsMap.get(managementType?.type?.name)
      } : {}}
      ref={cardContainerRef}
    >
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
      }}>
        <div style={{
          display: "flex",
          justifyContent: "space-between"
        }}>
          <div style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            gap: 10
          }}>
            <div>
              {initials &&
                <section className="bodyCard__initials">
                  {type == "farms" ? <FarmIcon /> : <></>}
                  {type == "areas" ? <AreaIcon /> : <></>}
                  {type == "micro-areas" ? <MicroAreaIcon /> : <></>}
                  {type == "batches" ? <AreaIcon /> : <></>}
                  <h3>{initials}</h3>
                </section>
              }
            </div>

            {showFarm && nameFarm ? (
              <section className="bodyCard_infos--card">
                <h3>{nameFarm}</h3>
              </section>
            ) : (
              ""
            )}

            {showArea && nameArea ? (
              <section className="bodyCard_infos--card">
                <h3>{nameArea}</h3>
              </section>
            ) : (
              ""
            )}
          </div>
        </div>

        {name && <h2 className="bodyCard__nameCard">{name}</h2>}

        {profile ? (
          <section className="bodyCard__profile">
            <h3>{profile}</h3>
          </section>
        ) : (
          ""
        )}

        <div className="bodyCard_infos">
          <>
            {initialBatch && (
              <ComponentChipOnlyView
                theme={"light"}
                label={initialBatch}
                variationStyles={{
                  label: {
                    fontWeight: 400,
                    fontSize: '18px',
                    fontFamily: 'Fira Code'
                  },
                }}
              />
            )}

            {qtdArea && qtdArea != 0 ? (
              <section className="bodyCard_infos--card">
                <AreaIcon />
                <h3>{qtdArea}</h3>
              </section>
            ) : (
              ""
            )}

            {qtdMicroArea && qtdMicroArea != 0 ? (
              <section className="bodyCard_infos--card">
                <MicroAreaIcon />
                <h3>{qtdMicroArea}</h3>
              </section>
            ) : (
              ""
            )}

            {qtdAnimals && qtdAnimals != 0 ? (
              <section className="bodyCard_infos--card">
                <AnimalsIcon />
                <h3>{qtdAnimals}</h3>
              </section>
            ) : (
              ""
            )}
          </>
        </div>
      </div>

      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: 'space-between',
        position: "relative"
      }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            overflowY: "hidden",
            alignItems: "end",
            gap: 10
          }}
          ref={overflowContainerRef}
        >
          {listInitialsMicroAreas?.map((microArea, index) => (
            <section
              key={index}
              className="bodyCard__initials"
              style={{
                minHeight: 52,
              }}
            >
              <h3>{microArea}</h3>
            </section>
          ))}
        </div>

        <div className="edit__container">
          <div className="edit__button">
            {type == "batches" ? <SearchIcon /> : <PenIcon />}
          </div>
        </div>
      </div>
    </div>
  );
}
