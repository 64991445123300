import { CSSProperties, useEffect, useRef, useState } from "react";
import { Polygon, Tooltip } from "react-leaflet";
import "./styles.css";

import {
  FlyToBounds,
  FlyToBoundsRefProps,
  IAnimationState,
} from "../../../../components/Leaflet/FlyToBounds/Index";
import { SimpleButtonClose } from "../../../../components/Modal/Contents/SimpleButtonClose/Index";
import { ButtonOnlyTitle } from "../../../../components/Buttons/ButtonOnlyTitle/Index";
import ContainerZoom1X from "../../../../components/Leaflet/ContainerZoom1X/Index";
import ContainerZoom4X from "../../../../components/Leaflet/ContainerZoom4X/Index";
import ContainerZoom2X from "../../../../components/Leaflet/ContainerZoom2X/Index";
import ContainerZoom3X from "../../../../components/Leaflet/ContainerZoom3X/Index";
import { PolylineFarm } from "../../../../components/PaddocksMap/PolylineFarm/Index";
import { BatchesIcons, ICustomBatch } from "../../../../components/PaddocksMap/BatchesIcons/Index";
import { TitleModal } from "../../../../components/Modal/Contents/TitleModal/Index";
import { LabelFarm1X } from "../../../../components/PaddocksMap/LabelFarm1X/Index";
import { TextModal } from "../../../../components/Modal/Contents/TextModal/Index";
import { AnimatedZoom } from "../../../../components/Leaflet/AnimatedZoom/Index";
import { AlertModal } from "../../../../components/Modal/Alert/Index";
import { useFilter } from "../../../../hooks/useFilter";

import { getCenterCoords } from "../../../../utils/leaflet/getCenterCoords";
import Constants from "../../../../constants/Index";
import api from "../../../../services/axios";

import { ICoords } from "../../../../@types/GoogleMaps/ICoord";
import { CustomControls } from "../../../../components/Leaflet/CustomControls/Index";
import { SpecsMouseOver } from "../../../../components/PaddocksMap/SpecsMouseOver/Index";
import { IBatch } from "../../../../@types/API/IBatches";

import { normalizeToScale } from "../../../../utils/numbers/normalizeToPercent";
import { getColorFromValue } from "../../../../utils/colors/colorFromAScale";

type IFarmData = {
  address: {
    city: { code: '5007109', name: 'Ribas do Rio Pardo' },
    state: string
    street: string
  },
  document: string,
  id: number,
  initials: string,
  map_coords: ICoords
  name: string
  owner_user: { id: number, name: string }
  properties: { season: { dry_initial: number, rain_initial: number } }
  state_registration: string
}

type IAreaData = {
  manager_user: { id: number, name: string },
  map_coords: ICoords,
  indoor_grazing_time: number,
  outdoor_grazing_time: number,
  id: number,
  name: string,
  initials: string,
  acreage: number,
  farm: {
    map_coords: ICoords,
    id: number,
    name: string,
    initials: string
  }
}

type IEntityAreaORFarm = { map_coords: ICoords, id: number, name: string, initials: string }

const contentStyleAlert = {
  width: "440px",
  height: "218px",
  borderRadius: "20px",
  overflow: "hidden",
  zIndex: 999,
} as CSSProperties;

export function PaddockTime() {
  const { farmFilter, areaFilter, defaultBGFarm, setHideMicroAreaFilter, setHideBatchFilter } = useFilter();

  const flytoBoundsRef = useRef<FlyToBoundsRefProps>();
  const [stateOfAnimation, setStateOfAnimation] = useState<IAnimationState>("end");
  const [farms, setFarms] = useState<IFarmData[]>([]);
  const [areas, setAreas] = useState<IAreaData[]>([]);
  const [microAreas, setMicroAreas] = useState<IMicroArea[]>([]);
  const [batchesIcons, setBatchesIcons] = useState<ICustomBatch[]>([]);
  const [errorModal, seterrorModal] = useState<boolean>(false);
  const [maxDescanso, setMaxDescanso] = useState(0);
  const [maxOcupacao, setMaxOcupacao] = useState(0);

  function overrideCentermap() {
    setStateOfAnimation("start");
    if (flytoBoundsRef && flytoBoundsRef.current && farmFilter)
      flytoBoundsRef.current?.flyToBounds(farmFilter?.coords);
  }

  const toggleAlert = () => seterrorModal((visible) => !visible);

  const getFarmsAllFarmsByUser = () => {
    api.get<IFarmData[]>('/farms/user')
      .then(({ data: farms }) => {
        setFarms(farms)
        getAllBatchesByUser(farms)
      })
      .catch(toggleAlert)
  }

  const getFarmsAllAreasByUser = () => {
    api.get<IAreaData[]>('/areas/user')
      .then(({ data: areas }) => setAreas(areas))
      .catch(toggleAlert)
  }

  const getFarmsAllMicroAreasByUser = () => {
    api.get<IMicroArea[]>('/micro-areas')
      .then(({ data: microAreas }) => {
        setMicroAreas(microAreas)
        setMaxDescanso(Math.max(...microAreas.map(el => el.days_without_grazing)));
        setMaxOcupacao(Math.max(...microAreas.map(el => el.days_grazing)));
      })
      .catch(toggleAlert)
  }

  const getAllBatchesByUser = (farms: IFarmData[]) => {
    farms.map(farm => {
      api.get<IBatch[]>(`/batches`, { params: { farm_id: farm.id } })
        .then(({ data: batches }) => {
          const batchIcons = batches.map(e => ({
            initials: e.initials,
            coords: getCenterCoords(e.current_micro_area.map_coords),
            id: e.id.toString(),
            areaId: e.area.id.toString(),
            visible: true
          }) as ICustomBatch)
          setBatchesIcons(prev => [...prev, ...batchIcons])
        })
        .catch(toggleAlert)
    })
  }

  function handleBGOppacity(farm: IFarmData, area: IEntityAreaORFarm) {
    if (areaFilter && area.id.toString() !== areaFilter.id.toString()) return 0.2;
    if (farm.id.toString() !== farmFilter.id.toString()) return 0.2;
    return 0.55;
  }

  const handleCurrentColor = (microArea: IMicroArea) => {
    const { days_grazing, days_without_grazing } = microArea

    const currentGrazingTime = days_grazing == 0 ? maxDescanso : maxOcupacao

    const currentValueColor = normalizeToScale(0, currentGrazingTime, days_grazing != 0 ? days_grazing : currentGrazingTime - days_without_grazing)

    if (days_grazing == 0)
      return getColorFromValue(currentValueColor, [0, 50, 100], ["#7B61FF", "#61FFF6", "#57CE71"])
    else
      return getColorFromValue(currentValueColor, [0, 50, 100], ["#FFCA80", "#FF9501", "#FF6161"])
  }

  useEffect(() => {
    document.title = Constants.TITLE_PADDOCKS_MAP_PADDOCK_TIME;

    getFarmsAllFarmsByUser()
    getFarmsAllAreasByUser()
    getFarmsAllMicroAreasByUser()
    setHideMicroAreaFilter(true)
    setHideBatchFilter(true)
  }, [])

  useEffect(() => {
    setBatchesIcons([])

    if (flytoBoundsRef && flytoBoundsRef.current && farmFilter)
      flytoBoundsRef.current?.flyToBounds(farmFilter?.coords);
  }, [farmFilter])

  return (
    <div className="defaultmap__container">
      <AlertModal visible={errorModal} animation="slideUp" contentStyle={contentStyleAlert}>
        <section className="defaultmap__alert--content">
          <SimpleButtonClose onClick={toggleAlert} />
          <div className="defaultmap__alert--texts">
            <TitleModal>{Constants.MESSAGE_INVALID_MODAL_TITLE}</TitleModal>
            <TextModal>{Constants.MESSAGE_INVALID_MODAL_FILTER}</TextModal>
          </div>
          <ButtonOnlyTitle onClick={toggleAlert} title="voltar" theme="info" />
        </section>
      </AlertModal>

      <FlyToBounds ref={flytoBoundsRef} onStateAnimation={(state) => setStateOfAnimation(state)}>
        {farms.map(farm => {
          return <AnimatedZoom key={farm.id} coordsToBoundZoom={farm.map_coords} updateZoomVisualization={stateOfAnimation}>
            <ContainerZoom1X>
              <LabelFarm1X
                key={farm.initials}
                position={getCenterCoords(farm.map_coords)}
                initials={farm.initials}
              />
            </ContainerZoom1X>

            <ContainerZoom2X>
              <Polygon
                key={farm.id}
                pathOptions={{ fillColor: defaultBGFarm }}
                positions={farm.map_coords}
                color={Constants.PADDOCKTIME_COLORS.WHITE}
                fillOpacity={0.55}
                fill={true}
                weight={2}
              >
                <Tooltip className="customToolTip__container" sticky>
                  <SpecsMouseOver microAreaLabel={`${farm.initials}`} type={"farm"} />
                </Tooltip>
              </Polygon>
            </ContainerZoom2X>

            <ContainerZoom3X>
              <PolylineFarm path={farm.map_coords} />
              <BatchesIcons batches={batchesIcons} />
              {areas
                .filter((microArea) => microArea.farm.id === farm.id)
                .map((area) => (
                  <Polygon
                    key={area.id}
                    pathOptions={{
                      fillOpacity: handleBGOppacity(farm, area),
                      color: Constants.PADDOCKTIME_COLORS.WHITE,
                      fillColor: Constants.PADDOCKTIME_COLORS.ORANGE,
                    }}
                    color={Constants.PADDOCKTIME_COLORS.WHITE}
                    positions={area.map_coords}
                    fill={true}
                    weight={2}
                  >
                    <Tooltip className="customToolTip__container" sticky>
                      <SpecsMouseOver
                        microAreaLabel={`${farm?.initials}-${area.initials}`}
                        type={"area"}
                      />
                    </Tooltip>
                  </Polygon>
                ))}
            </ContainerZoom3X>

            <ContainerZoom4X>
              <PolylineFarm path={farm.map_coords} />
              <BatchesIcons batches={batchesIcons} />
              {microAreas
                .filter((microArea) => microArea.farm.id === farm.id)
                .map((microArea) => (
                  <Polygon
                    key={microArea.id}
                    pathOptions={{
                      fillOpacity: handleBGOppacity(farm, microArea.area),
                      color: Constants.PADDOCKTIME_COLORS.WHITE,
                      fillColor: handleCurrentColor(microArea),
                    }}
                    positions={microArea.map_coords}
                    fill={true}
                    weight={2}
                  >
                    <Tooltip className="customToolTip__container" sticky>
                      <SpecsMouseOver
                        className={"havebatch"}
                        microAreaLabel={`${farm?.initials}-${microArea.area.initials}-${microArea.initials}`}
                        type={"batch"}
                      />
                      <div className="infoSpecsDetails__container">
                        <h3 className="qtdDetails">
                          <>
                            {areas.filter(el => el.id == microArea.area.id)[0] &&
                              <div>
                                Maximo de dias
                                {microArea.days_grazing != 0 ?
                                  <>
                                    <br />de ocupação: {areas.filter(el => el.id == microArea.area.id)[0].indoor_grazing_time}
                                  </>
                                  :
                                  <>
                                    <br />de descanso: {areas.filter(el => el.id == microArea.area.id)[0].outdoor_grazing_time}
                                  </>
                                }
                              </div>
                            }
                            <div className="delimiter" style={{ marginTop: 10, marginBottom: 10 }} />
                            {microArea.days_grazing != 0 ?
                              <div>
                                {microArea.days_grazing} dias
                                <br />
                                de ocupação
                              </div>
                              :
                              <div>
                                {microArea.days_without_grazing} dias
                                <br />
                                de descanso
                              </div>
                            }
                          </>
                        </h3>
                      </div>
                    </Tooltip>
                  </Polygon>
                ))}
            </ContainerZoom4X>
          </AnimatedZoom>
        })}

      </FlyToBounds>
      <CustomControls overrideCentermap={overrideCentermap} />
      <section className="capacitybvroute__barnumber--container">
        {maxOcupacao != 0 &&
          <div>
            <strong className="capacitybvroute__barnumber--title">Dias de ocupação</strong>
            <div className="paddocktime__barnumber--bar1" />
            <div className="paddocktime__barnumber--containerlegend">
              <span>0</span>
              <span>{maxOcupacao}</span>
            </div>
          </div>
        }
        <br />
        {maxDescanso != 0 &&
          <div>
            <strong className="capacitybvroute__barnumber--title">Dias de descanso</strong>
            <div className="paddocktime__barnumber--bar2" />
            <div className="paddocktime__barnumber--containerlegend">
              <span>0</span>
              <span>{maxDescanso}</span>
            </div>
          </div>
        }
      </section>
    </div>
  );
}
