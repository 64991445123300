import { Navigate, Route, Routes } from "react-router-dom";
import Constants from "../constants/Index";
import { FilterContextProvider } from "../contexts/FilterContext";
import { OnboardingProvider } from "../contexts/OnboardingContext";
import { Onboarding } from "../pages/Onboarding/Template/Index";
import { PastureCapacity } from "../pages/PaddocksMap/Maps/PastureCapacity/Index";
import { Index } from "../pages/PaddocksMap/Maps/DefaultMaps/Index";
import { PaddockTime } from "../pages/PaddocksMap/Maps/PaddockTime/Index";
import { PastureManagement } from "../pages/PaddocksMap/Maps/PastureManagement/Index";
import { PaddocksMapIndex } from "../pages/PaddocksMap/Template";
import { isAuthenticated } from "../services/auth";
import { TaskCreationProvider } from "../contexts/TaskCreationContext";
import { PdfPage } from "../pages/Pdf";
import DashboardCattleMap from "../pages/Dashboard/CattleMap";
import DashboardSupplementationInventoryControl from "../pages/Dashboard/Supplementation/InventoryControl";
import DashboardSupplementationProductReport from "../pages/Dashboard/Supplementation/ProductReport";
import DashboardSupplementationProductConsumption from "../pages/Dashboard/Supplementation/ProductConsumption";

type AuthRoutesProps = {
  mapLoad: boolean;
};

interface PropType {
  component: React.FC;
}

const PrivateRoute = ({ component: Component }: PropType) => {
  if (isAuthenticated()) return <Component />;
  return <Navigate to="/entrar" />;
};

export function AuthRoutes({ mapLoad }: AuthRoutesProps) {
  return (
    <>
      {/* [INICIO] Onboarding */}
      <OnboardingProvider>
        <Routes>
          <Route path="bemvindo">
            <Route
              path={Constants.ONBOARDING}
              element={<PrivateRoute component={Onboarding}></PrivateRoute>}
            />
          </Route>
        </Routes>
      </OnboardingProvider>
      {/* [FIM] Onboarding */}

      <FilterContextProvider>
        <OnboardingProvider>
          <TaskCreationProvider>
            <Routes>
              <Route path={Constants.EVALUATION_REPORT} element={<PdfPage />} />

              <Route path={Constants.PADDOCKS_MAP} element={<PrivateRoute component={PaddocksMapIndex} />}>
                <Route path="" element={<Index />} />

                <Route path={Constants.PADDOCKS_MAP_PADDOCK_TIME} element={<PaddockTime />} />

                <Route path={Constants.PADDOCKS_MAP_PASTURE_MANAGEMENT_TYPE} element={<PastureManagement filter={"all"} />} />
                <Route path={Constants.PADDOCKS_MAP_PASTURE_MANAGEMENT_HEIGHT} element={<PastureManagement filter={"height"} />} />
                <Route path={Constants.PADDOCKS_MAP_PASTURE_MANAGEMENT_RATE} element={<PastureManagement filter={"rate"} />} />
                <Route path={Constants.PADDOCKS_MAP_PASTURE_MANAGEMENT_DAY} element={<PastureManagement filter={"day"} />} />

                <Route path={Constants.PADDOCKS_MAP_CAPACITY} element={<PastureCapacity view={"immediate"} />} />
                <Route path={Constants.PADDOCKS_MAP_CAPACITY_IMMEDIATE} element={<PastureCapacity view={"immediate"} />} />
                <Route path={Constants.PADDOCKS_MAP_CAPACITY_AVAREGE} element={<PastureCapacity view={"avarege"} />} />

                <Route path={Constants.PADDOCKS_MAP_DASHBOARD_CATTLE_MAP} element={<DashboardCattleMap />} />
                <Route path={Constants.PADDOCKS_MAP_DASHBOARD_SUPPLEMENTATION} element={<DashboardSupplementationInventoryControl />} />
                <Route path={Constants.PADDOCKS_MAP_DASHBOARD_SUPPLEMENTATION_CONTROL} element={<DashboardSupplementationInventoryControl />} />
                <Route path={Constants.PADDOCKS_MAP_DASHBOARD_SUPPLEMENTATION_REPORT} element={<DashboardSupplementationProductReport />} />
                <Route path={Constants.PADDOCKS_MAP_DASHBOARD_SUPPLEMENTATION_CONSUMPTION} element={<DashboardSupplementationProductConsumption />} />
              </Route>
            </Routes>
          </TaskCreationProvider>
        </OnboardingProvider>
      </FilterContextProvider>
    </>
  );
}
